<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="70%"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="120px"
        :model="potentialFaultSetting"
        :rules="ruleValidate">
        <el-tabs v-model="activeTab" type="card">
          <el-tab-pane :label="$t('potentialFaultSetting.baseInfo')" name="first">
            <div class="vm-separate">
              <el-form-item :label="$t('potentialFaultSetting.faultTemplate')" prop="faultTemplateId">
                <el-input
                  v-model="potentialFaultSetting.faultTemplateName"
                  :placeholder="$t('common.pleaseSelect')"
                  readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectFault.open()"></el-button>
                </el-input>
                <fault-select ref="selectFault" @select="onSelectFault"></fault-select>
              </el-form-item>
              <div></div>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('potentialFaultSetting.name')" prop="name">
                <el-input v-model="potentialFaultSetting.name" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <div></div>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('potentialFaultSetting.warningValue')" prop="warningValue">
                <el-input
                  v-model.number="potentialFaultSetting.warningValue"
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <div></div>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('potentialFaultSetting.statPeriod')" prop="statPeriod">
                <el-input
                  v-model.number="potentialFaultSetting.statPeriod"
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <div></div>
            </div>
            <div class="vm-separate" style="width: 70%">
              <el-form-item :label="$t('potentialFaultSetting.note')" prop="note">
                <el-input
                  v-model="potentialFaultSetting.note"
                  :placeholder="$t('common.pleaseEnter')"
                  type="textarea"
                  :maxlength="500"
                  :rows="4"></el-input>
              </el-form-item>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('potentialFaultSetting.details')" name="second">
            <el-row>
              <el-table
                :data="potentialFaultSetting.potentialFaultSettingDetailList"
                max-height="300"
                border
                stripe
                style="width: 100%"
              >
                <el-table-column prop="faultCode" :label="$t('potentialFaultSetting.faultCode')" width="150px">
                  <template slot-scope="scope">
                    <el-input
                      v-model.number="scope.row.faultCode"
                      :placeholder="$t('potentialFaultSetting.tip.faultCode')"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="weight" :label="$t('potentialFaultSetting.weight')" width="150px">
                  <template slot-scope="scope">
                    <el-input
                      v-model.number="scope.row.weight"
                      :placeholder="$t('potentialFaultSetting.tip.weight')"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="note" :label="$t('potentialFaultSetting.note')" width="300px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.note"></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.operation')" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
                      {{$t("common.delete")}}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div style="margin-top: 10px;float: right">
                <el-button type="text" @click="handleAddFaultDetailClick()"> + {{$t("common.add")}}</el-button>
              </div>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import FaultSelect from "../fault/FaultSelect.vue";

  const moduleName = "potentialFaultSetting";

  export default {
    components: {
      FaultSelect,
    },
    data() {
      return {
        submitLoading: false,
        saveDisabled: false,
        dialogVisible: false,
        contentLoading: false,
        activeTab: "first",
        potentialFaultSetting: {
          id: 0,
          name: "",
          warningValue: 0,
          statPeriod: 0,
          note: "",
          faultTemplateId: 0,
          faultTemplateName: "",
          potentialFaultSettingDetailList: [],
        },
        ruleValidate: {
          warningValue: [
            {
              required: true,
              min: 1,
              type: "number",
              message: this.$t("potentialFaultSetting.tip.warningValue"),
              trigger: "blur",
            },
          ],
          statPeriod: [
            {
              required: true,
              min: 1,
              type: "number",
              message: this.$t("potentialFaultSetting.tip.statPeriod"),
              trigger: "blur",
            },
          ],
          faultTemplateId: [
            {
              required: true,
              min: 1,
              type: "number",
              message: this.$t("potentialFaultSetting.tip.faultTemplate"),
              trigger: "blur, change",
            },
          ],
          name: [
            {required: true, message: this.$t("potentialFaultSetting.tip.name"), trigger: "blur"},
          ],
        },
      };
    },
    computed: {
      title() {
        return (this.potentialFaultSetting.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("potentialFaultSetting.potentialFaultSetting");
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.potentialFaultSetting.id = id;
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.potentialFaultSetting.id).then(res => {
          this.potentialFaultSetting = Object.assign(this.potentialFaultSetting, res.data);
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onSelectFault(row) {
        this.potentialFaultSetting.faultTemplateId = row.id;
        this.potentialFaultSetting.faultTemplateName = row.name;
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save(moduleName, this.potentialFaultSetting).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.potentialFaultSetting.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.contentLoading = false;
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleAddFaultDetailClick() {
        let potentialFaultSettingDetail = {faultCode: "", weight: "", note: ""};
        this.potentialFaultSetting.potentialFaultSettingDetailList.push(potentialFaultSettingDetail);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
